export const externalList = [
  {
    value: 'AT',
    prefix: '43',
    countryName: 'Österreich',
    label: 'Austria',
    icon: '🇦🇹',
    types: [
      {
        name: 'IdentityCard',
        id: 11,
      },
      {
        name: 'Passport',
        id: 12,
      },
    ],
  },
  {
    value: 'BE',
    prefix: '32',
    countryName: 'België',
    label: 'Belgium',
    icon: '🇧🇪',
    types: [
      {
        name: 'IdentityCard',
        id: 15,
      },
      {
        name: 'Passport',
        id: 16,
      },
    ],
  },
  {
    value: 'BG',
    prefix: '359',
    countryName: 'България',
    label: 'Bulgaria',
    icon: '🇧🇬',
    types: [
      {
        name: 'IdentityCard',
        id: 19,
      },
      {
        name: 'Passport',
        id: 20,
      },
    ],
  },
  {
    value: 'CY',
    prefix: '357',
    countryName: 'Κύπρος',
    label: 'Cyprus',
    icon: '🇨🇾',
    types: [
      {
        name: 'IdentityCard',
        id: 47,
      },
      {
        name: 'Passport',
        id: 48,
      },
    ],
  },
  {
    value: 'CZ',
    prefix: '420',
    countryName: 'Česko',
    label: 'Czechia',
    icon: '🇨🇿',
    types: [
      {
        name: 'Passport',
        id: 49,
      },
      {
        name: 'IdentityCard',
        id: 50,
      },
    ],
  },
  {
    value: 'DE',
    prefix: '49',
    countryName: 'Deutschland',
    label: 'Germany',
    icon: '🇩🇪',
    types: [
      {
        name: 'IdentityCard',
        id: 53,
      },
      {
        name: 'Passport',
        id: 51,
      },
    ],
  },
  {
    value: 'DK',
    prefix: '45',
    countryName: 'Danmark',
    label: 'Denmark',
    icon: '🇩🇰',
    types: [
      {
        name: 'Passport',
        id: 55,
      },
    ],
  },
  {
    value: 'EE',
    prefix: '372',
    countryName: 'Eesti',
    label: 'Estonia',
    icon: '🇪🇪',
    types: [
      {
        name: 'IdentityCard',
        id: 63,
      },
      {
        name: 'Passport',
        id: 64,
      },
    ],
  },
  {
    value: 'ES',
    prefix: '34',
    countryName: 'España',
    label: 'Spain',
    icon: '🇪🇸',
    types: [
      {
        name: 'IdentityCard',
        id: 62,
      },
      {
        name: 'Passport',
        id: 61,
      },
    ],
  },
  {
    value: 'FI',
    prefix: '358',
    countryName: 'Suomi',
    label: 'Finland',
    icon: '🇫🇮',
    types: [
      {
        name: 'IdentityCard',
        id: 66,
      },
      {
        name: 'Passport',
        id: 67,
      },
    ],
  },
  {
    value: 'FR',
    prefix: '33',
    countryName: 'France',
    label: 'France',
    icon: '🇫🇷',
    types: [
      {
        name: 'IdentityCard',
        id: 296,
      },
      {
        name: 'Passport',
        id: 68,
      },
    ],
  },
  {
    value: 'GB',
    prefix: '44',
    countryName: 'United Kingdom',
    label: 'United Kingdom',
    icon: '🇬🇧',
    types: [
      {
        name: 'Passport',
        id: 71,
      },
    ],
  },
  {
    value: 'GR',
    prefix: '30',
    countryName: 'Ελλάς',
    label: 'Greece',
    icon: '🇬🇷',
    types: [
      {
        name: 'IdentityCard',
        id: 215,
      },
      {
        name: 'Passport',
        id: 76,
      },
    ],
  },
  {
    value: 'HR',
    prefix: '385',
    countryName: 'Hrvatska',
    label: 'Croatia',
    icon: '🇭🇷',
    types: [
      {
        name: 'IdentityCard',
        id: 193,
      },
      {
        name: 'Passport',
        id: 80,
      },
    ],
  },
  {
    value: 'HU',
    prefix: '36',
    countryName: 'Magyarország',
    label: 'Hungary',
    icon: '🇭🇺',
    types: [
      {
        name: 'Passport',
        id: 82,
      },
      {
        name: 'IdentityCard',
        id: 83,
      },
    ],
  },
  {
    value: 'IE',
    prefix: '353',
    countryName: 'Ireland',
    label: 'Ireland',
    icon: '🇮🇪',
    types: [
      {
        name: 'IdentityCard',
        id: 240,
      },
      {
        name: 'Passport',
        id: 85,
      },
    ],
  },
  {
    value: 'IS',
    prefix: '354',
    countryName: 'Ísland',
    label: 'Iceland',
    icon: '🇮🇸',
    types: [
      {
        name: 'Passport',
        id: 88,
      },
    ],
  },
  {
    value: 'IT',
    prefix: '39',
    countryName: 'Italia',
    label: 'Italy',
    icon: '🇮🇹',
    types: [
      {
        name: 'IdentityCard',
        id: 200,
      },
      {
        name: 'Passport',
        id: 90,
      },
    ],
  },
  {
    value: 'LI',
    prefix: '423',
    countryName: 'Liechtenstein',
    label: 'Liechtenstein',
    icon: '🇱🇮',
    types: [
      {
        name: 'Passport',
        id: 105,
      },
      {
        name: 'IdentityCard',
        id: 231,
      },
    ],
  },
  {
    value: 'LT',
    prefix: '370',
    countryName: 'Lietuva',
    label: 'Lithuania',
    icon: '🇱🇹',
    types: [
      {
        name: 'Passport',
        id: 107,
      },
      {
        name: 'IdentityCard',
        id: 108,
      },
    ],
  },
  {
    value: 'LU',
    prefix: '352',
    countryName: 'Lëtezebuerg',
    label: 'Luxembourg',
    icon: '🇱🇺',
    types: [
      {
        name: 'Passport',
        id: 109,
      },
      {
        name: 'IdentityCard',
        id: 110,
      },
    ],
  },
  {
    value: 'LV',
    prefix: '371',
    countryName: 'Latvija',
    label: 'Latvia',
    icon: '🇱🇻',
    types: [
      {
        name: 'Passport',
        id: 111,
      },
      {
        name: 'IdentityCard',
        id: 112,
      },
    ],
  },
  {
    value: 'MT',
    prefix: '356',
    countryName: 'Malta',
    label: 'Malta',
    icon: '🇲🇹',
    types: [
      {
        name: 'Passport',
        id: 124,
      },
      {
        name: 'IdentityCard',
        id: 125,
      },
    ],
  },
  {
    value: 'NL',
    prefix: '31',
    countryName: 'Nederland',
    label: 'The Netherlands',
    icon: '🇳🇱',
    types: [
      {
        name: 'IdentityCard',
        id: 136,
      },
      {
        name: 'Passport',
        id: 137,
      },
    ],
  },
  {
    value: 'NO',
    prefix: '37',
    countryName: 'Norge',
    label: 'Norway',
    icon: '🇳🇴',
    types: [
      {
        name: 'IdentityCard',
        id: 283,
      },
      {
        name: 'Passport',
        id: 138,
      },
    ],
  },
  {
    value: 'PL',
    prefix: '48',
    countryName: 'Polska',
    label: 'Poland',
    icon: '🇵🇱',
    types: [
      {
        name: 'IdentityCard',
        id: 146,
      },
      {
        name: 'Passport',
        id: 145,
      },
    ],
  },
  {
    value: 'PT',
    prefix: '351',
    countryName: 'Portugal',
    label: 'Portugal',
    icon: '🇵🇹',
    types: [
      {
        name: 'Passport',
        id: 147,
      },
      {
        name: 'IdentityCard',
        id: 148,
      },
    ],
  },
  {
    value: 'RO',
    prefix: '40',
    countryName: 'România',
    label: 'Romania',
    icon: '🇷🇴',
    types: [
      {
        name: 'Passport',
        id: 154,
      },
      {
        name: 'IdentityCard',
        id: 301,
      },
    ],
  },
  {
    value: 'SE',
    prefix: '46',
    countryName: 'Sverige',
    label: 'Sweden',
    icon: '🇸🇪',
    types: [
      {
        name: 'IdentityCard',
        id: 168,
      },
      {
        name: 'Passport',
        id: 169,
      },
    ],
  },
  {
    value: 'SI',
    prefix: '386',
    countryName: 'Slovenija',
    label: 'Slovenia',
    icon: '🇸🇮',
    types: [
      {
        name: 'Passport',
        id: 166,
      },
      {
        name: 'IdentityCard',
        id: 167,
      },
    ],
  },
  {
    value: 'SK',
    prefix: '421',
    countryName: 'Slovensko',
    label: 'Slovakia',
    icon: '🇸🇰',
    types: [
      {
        name: 'Passport',
        id: 163,
      },
      {
        name: 'IdentityCard',
        id: 165,
      },
    ],
  },
]
