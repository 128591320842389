import React, { useEffect, useRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import phone from 'phone'
import { components } from 'react-select'
import { SelectInput, SelectOption } from '../SelectInput/SelectInput'
import { TextInput } from '../TextInput/TextInput'
import { countriesList } from './countriesList'
import { externalList } from './countriesListExternal'
import { useOnClickOutside, useResizeObserver } from 'usehooks-ts'

import {
  Container,
  CountryPrefixSelectContainer,
  CountryFlag,
  CountryItemInner,
} from './CountryPrefixSelect.styles'

const { Option, SingleValue } = components

interface CountryPrefixSelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  value: string
  defaultCountryCode?: string
  countryCode?: string
  className?: string
  onChange: (e) => void
  onError?: (boolean) => void
  required?: boolean
  requiredErrorLabel?: string
  phoneErrorLabel?: string
  phoneRegex?: string
  disabled?: boolean
  selectStyles?: {
    borderRadius: string
    borderWidth: string
    color?: string
    colorDisabled?: string
    backgroundColor?: string
    border?: string
    borderFocus?: string
    outline?: string
    borderColorFocus?: string
    colorFocus?: string
    backgroundColorFocus?: string
    colorSelected?: string
    backgroundColorSelected?: string
    menuMarginTop?: string
    menuBorderWidth?: string
    indicatorColorFocus?: string
    indicatorColorHover?: string
    danger: string
    dangerLight: string
    placeholder?: string
    autoFocus?: boolean
    defaultMenuIsOpen?: boolean
    fontSize?: string
  }
  inputStyles?: {
    bgColor?: string
    padding?: string
    borderRadius?: string
    border?: string
    boxShadow?: string
    margin?: string
    color?: string
    colorFocus?: string
    bgColorFocus?: string
    borderFocus?: string
    bgColorDisabled?: string
    borderDisabled?: string
    colorDisabled?: string
    fontSize?: string
    fontWeight?: string
    outline?: string
    errorColor?: string
    placeholder?: string
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalList?: any[]
}

function getExternalFlag(countryCode: string) {
  const country = countriesList.find((country) => country.value == countryCode)
  return country.icon
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountrySingleValue = (props: any) => (
  <SingleValue {...props}>
    <CountryItemInner>
      {externalList
        ? props.data.icon && (
            <CountryFlag>
              <LazyLoadImage
                alt={props.data.value}
                src={getExternalFlag(props.data.value)}
              />
            </CountryFlag>
          )
        : props.data.icon && (
            <CountryFlag>
              <LazyLoadImage alt={props.data.value} src={props.data.icon} />
            </CountryFlag>
          )}
      <span>+{props.data.prefix}</span>
    </CountryItemInner>
  </SingleValue>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountryOption = (props: any) => (
  <Option {...props}>
    <CountryItemInner>
      {externalList
        ? props.data.icon && (
            <CountryFlag>
              <LazyLoadImage
                alt={props.data.value}
                src={getExternalFlag(props.data.value)}
              />
            </CountryFlag>
          )
        : props.data.icon && (
            <CountryFlag>
              <LazyLoadImage alt={props.data.value} src={props.data.icon} />
            </CountryFlag>
          )}
      <span>{props.data.label}</span>
    </CountryItemInner>
  </Option>
)

type Size = {
  width?: number
  height?: number
}

const optionsList = externalList
const optionsListWithPrefix = optionsList.map((item) => {
  return {
    ...item,
    label: `+${item.prefix} ${item.countryName} | ${item.label}`,
  }
})

export const CountryPrefixSelect = (props: CountryPrefixSelectProps) => {
  const [selectValue, setSelectValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [fullValue, setFullValue] = useState('')
  const [focused, setFocused] = useState(false)
  const [menuWidth, setMenuWidth] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const { width = 0, height = 0 } = useResizeObserver({
    ref,
    box: 'border-box',
  })

  function handleSelectChange(value) {
    setSelectValue(value)
  }

  function handleInputChange(value) {
    setInputValue(value)
  }

  useEffect(() => {
    if (inputValue)
      setFullValue(
        `${selectValue ? '+' + selectValue.prefix : ''}${inputValue}`
      )
  }, [selectValue, inputValue])

  useEffect(() => {
    const componentContainer = document.getElementsByClassName(
      'authflow-country-prefix-select'
    )

    if (componentContainer.length) {
      setMenuWidth(componentContainer[0].clientWidth)
    }
  }, [width])

  useEffect(() => {
    handleValidation(fullValue)
    props.onChange(fullValue)
  }, [fullValue, props.phoneErrorLabel, props.requiredErrorLabel])

  useEffect(() => {
    let selectedValueIndex = -1
    const phoneNumber = props.value ? props.value.replace(/^[+0]+/g, '') : ''

    if (phoneNumber.length) {
      selectedValueIndex = optionsList.findIndex((item) => {
        return (
          item.prefix === phoneNumber.slice(0, 2) ||
          item.prefix === phoneNumber.slice(0, 3)
        )
      })
    }

    if (props.defaultCountryCode && selectedValueIndex === -1) {
      selectedValueIndex = optionsList.findIndex((item) => {
        return item.value === props.defaultCountryCode.toUpperCase()
      })
    }

    if (selectedValueIndex > -1) {
      const prefix = optionsList[selectedValueIndex]?.prefix

      handleSelectChange(optionsListWithPrefix[selectedValueIndex])
      handleInputChange(
        phoneNumber && prefix.length && phoneNumber.slice(prefix.length)
      )
    }
  }, [props.value])

  useEffect(() => {
    let selectedValueIndex = -1
    const phoneNumber = props.value ? props.value.replace(/^[+0]+/g, '') : ''

    if (!phoneNumber.length && props.countryCode && selectedValueIndex === -1) {
      selectedValueIndex = optionsList.findIndex((item) => {
        return item.value === props.countryCode.toUpperCase()
      })

      if (props.defaultCountryCode && selectedValueIndex === -1) {
        selectedValueIndex = optionsList.findIndex((item) => {
          return item.value === props.defaultCountryCode.toUpperCase()
        })
      }
    }

    if (selectedValueIndex > -1) {
      const prefix = optionsList[selectedValueIndex]?.prefix

      handleSelectChange(optionsListWithPrefix[selectedValueIndex])
      handleInputChange(
        phoneNumber && prefix.length && phoneNumber.slice(prefix.length)
      )
    }
  }, [props.countryCode])

  const handleClickOutside = () => {
    setFocused(false)
  }

  const handleClickInside = () => {
    setFocused(true)
  }

  useOnClickOutside(ref, handleClickOutside)

  function handleValidation(value: string) {
    props.onError && props.onError('')

    const rePhone = phone(value)
    const rePhoneWithRegex = new RegExp(props.phoneRegex)

    if (value && value.length) {
      if (!rePhoneWithRegex.test(value) || !rePhone.isValid) {
        props.onError &&
          props.onError(
            props.phoneErrorLabel
              ? props.phoneErrorLabel
              : 'Please enter a valid phone number with country code prefix'
          )
      }
    } else {
      if (props.required && focused) {
        props.onError &&
          props.onError(
            props.requiredErrorLabel
              ? props.requiredErrorLabel
              : 'Please fill the required field'
          )
      }
    }
  }

  return (
    <Container>
      <CountryPrefixSelectContainer ref={ref} onClick={handleClickInside}>
        <SelectInput
          options={optionsListWithPrefix}
          value={selectValue}
          onChange={handleSelectChange}
          isClearable={false}
          disabled={props.disabled}
          components={{
            SingleValue: CountrySingleValue,
            Option: CountryOption,
          }}
          className={props.className ? props.className : ''}
          {...props.selectStyles}
          borderRadius={`${
            props.selectStyles?.borderRadius
              ? props.selectStyles?.borderRadius
              : '0px'
          } 0px 0px ${
            props.selectStyles?.borderRadius
              ? props.selectStyles?.borderRadius
              : '0px'
          }`}
          border="transparent"
          borderFocus="transparent"
          borderWidth="0px"
          outline="none"
          width="130px"
          menuWidth={`${menuWidth}px`}
          valueContainerPadding="1px 0 1px 14px"
          autoFocus={
            props.selectStyles?.autoFocus
              ? props.selectStyles?.autoFocus
              : false
          }
          defaultMenuIsOpen={false}
          position="absolute"
          placeholder={
            props.selectStyles?.placeholder
              ? props.selectStyles?.placeholder
              : ' '
          }
        />
        <TextInput
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          type="phone"
          {...props.inputStyles}
          padding="10px 16px 10px 144px"
          className={focused ? 'focused' : undefined}
          placeholder={
            props.inputStyles?.placeholder ? props.inputStyles?.placeholder : ''
          }
        />
      </CountryPrefixSelectContainer>
    </Container>
  )
}
