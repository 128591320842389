import axios from 'axios'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { posTheme } from '../constants/pos.theme'
import { amexTheme } from '../constants/amex.theme'
import { bmwTheme } from '../constants/bmw.theme'
import { checkoutTheme } from '../constants/checkout.theme'
import { ikanoTheme } from '../constants/ikano.theme'
import { miniTheme } from '../constants/mini.theme'
import { signicatTheme } from '../constants/signicat.theme'

const themeMap = {
  myPosTheme: posTheme,
  amexTheme: amexTheme,
  bmwTheme: bmwTheme,
  checkoutTheme: checkoutTheme,
  ikanoTheme: ikanoTheme,
  miniTheme: miniTheme,
  signicatTheme: signicatTheme,
}

interface StepInterface {
  order: number
  completed: boolean
}

export class AppStateStore {
  rootStore
  authorization = false
  initalAuthCheck = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any = posTheme
  themeName: string = 'myPosTheme'
  pageWidth: number = 0
  pageHeight: number = 0
  acceptedLanguages: string[] = ['en']
  identTypes: string[] = ['eidv']

  constructor(rootStore) {
    makeAutoObservable(this)

    this.rootStore = rootStore
  }

  @action.bound setAuthorization(auth: boolean): void {
    this.authorization = auth
  }

  @action.bound changeTheme(themeId: string): void {
    if (themeMap[themeId]) {
      this.theme = themeMap[themeId]
      this.themeName = themeId
    }
  }

  @action.bound setTheme(theme): void {
    this.theme = theme
  }

  @action.bound setPageWidth(width: number): void {
    this.pageWidth = width
  }

  @action.bound setPageHeight(height: number): void {
    this.pageHeight = height
  }

  @action.bound skipAuth(): void {
    this.initalAuthCheck = true
    this.authorization = true
    // this.rootStore.Router.setLocation(window.location.pathname.substring(1))
  }

  @action.bound setAcceptedLanguages(acceptedLanguages: string[]) {
    this.acceptedLanguages = acceptedLanguages
  }

  @action.bound setIdentTypes(identTypes: string[]) {
    this.identTypes = identTypes
  }

  // to be rewritten
  @action
  async checkLogin() {
    try {
      const res = await axios(
        'https://merchant-poc-2577551f9ce2.herokuapp.com/auth/check',
        {
          method: 'GET',
          withCredentials: true,
        }
      )

      console.log(res)

      runInAction(() => {
        this.initalAuthCheck = true
        this.authorization = true
        // this.rootStore.Router.setLocation(window.location.pathname.substring(1))
      })
    } catch (e) {
      runInAction(() => {
        this.initalAuthCheck = true
        this.authorization = true // set to "true" to skip authorization for local development
        // this.rootStore.Router.setLocation(window.location.pathname.substring(1))
      })
      console.log(e)
    }
  }

  @action.bound async fetchConfiguration() {
    try {
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/configuration`,
        { withCredentials: true }
      )

      if (res.data) {
        this.setAcceptedLanguages(res.data.acceptedLanguages)
        this.setIdentTypes(res.data.identTypes)
      }
    } catch (e) {
      console.log(e)
    }
  }
}
