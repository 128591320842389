import { observer } from 'mobx-react'
import axios from 'axios'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  SimpleSelect,
  SelectItem,
} from '../../../../components/SimpleSelect/SimpleSelect'
import { StoreContext } from '../../../../components/App'
import { TextInput } from '../../../../components/TextInput/TextInput'
import { Container, InputWrap } from './summary.styles'
import {
  FormSection,
  FormSectionOptional,
  InputRadio,
  ProceedButton,
} from '../enroll.styles'
import { AnimatedBorder } from '../../../../components/AnimatedInput/AnimatedBorder'
import {
  GicsSelect,
  GicsSelectValue,
} from '../../../../components/GicsSelect/GicsSelect'
import { InputPlaceholder } from '../../../../components/AnimatedInput/AnimatedBorder.styles'
import { gicsList } from '../../../../constants/gicsFlat'
import { Icon } from '../../../../components/Icons/Icon.styles'
import IconPencil from '../../../../assets/pencil.svg'
import {
  CountrySelect,
  CountrySelectItem,
} from '../../../../components/CountrySelect/CountrySelect'
import { findCountryByValue } from '../../../../components/CountrySelect/methods'
import { ToRight } from '../../EnrollCompany.styles'
import { Asterisk } from '../../../../components/PersonCard/PersonCard.styles'

const mockOptions: SelectItem[] = [
  { value: '1', label: '???' },
  { value: '2', label: '???' },
  { value: '3', label: '???' },
  { value: '4', label: '???' },
]

interface CompanyDataProps {
  initialEdit: boolean
}

export const CompanyData = observer(({ initialEdit }: CompanyDataProps) => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    formLabels: transForm,
    enrollCompanyPage: {
      companyData: trans,
      businessAddress: transAddress,
      gicsDataSet: gicsTrans,
    },
  } = store.TranslationsState.translations

  const [edit, setEdit] = useState(initialEdit)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isProceedDisabled, setIsProceedDisabled] = useState(true)
  const [kvkNumber, setKvkNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [vatNumber, setVatNumber] = useState('')
  const [website, setWebsite] = useState('')
  const [companyLegalForm, setCompanyLegalForm] = useState({
    value: '',
    label: '',
  })
  const [businessSector, setBusinessSector] = useState<GicsSelectValue>([])
  const [activity, setActivity] = useState({ value: '', label: '' })
  const [useCase, setUseCase] = useState({ value: '', label: '' })
  const [salesChannel, setSalesChannel] = useState({ value: '', label: '' })

  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState<CountrySelectItem>({
    value: '',
    label: transForm.selectCountry,
  })

  const [showTrading, setShowTrading] = useState(false)

  const [tradingAddress1, setTradingAddress1] = useState('')
  const [tradingAddress2, setTradingAddress2] = useState('')
  const [tradingCity, setTradingCity] = useState('')
  const [tradingPostalCode, setTradingPostalCode] = useState('')
  const [tradingCountry, setTradingCountry] = useState<CountrySelectItem>({
    value: '',
    label: transForm.selectCountry,
  })

  const CompanyLegalFormOptions: SelectItem[] = useMemo(
    () => [
      { value: 'soleProprietorship', label: trans.soleProprietorship },
      { value: 'private', label: trans.private },
      { value: 'partnership', label: trans.partnership },
      { value: 'selfEmployed', label: trans.selfEmployed },
      { value: 'foundation', label: trans.foundation },
      { value: 'association', label: trans.association },
      { value: 'branch', label: trans.branch },
      {
        value: 'associationInclCooperative',
        label: trans.associationInclCooperative,
      },
    ],
    [trans]
  )

  const SalesChannelOptions: SelectItem[] = useMemo(
    () => [
      { value: 'ownstores', label: trans.ownstores },
      { value: 'reseller', label: trans.reseller },
      { value: 'thirdparty', label: trans.thirdparty },
    ],
    [trans]
  )

  const UseCaseOptions: SelectItem[] = useMemo(
    () => [
      { value: 'instore', label: trans.instore },
      { value: 'online', label: trans.online },
      { value: 'both', label: trans.both },
      { value: 'other', label: trans.other },
    ],
    [trans]
  )

  const getMerchantData = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/data`,
        {
          withCredentials: true,
        }
      )

      if (res.data && res.data.merchantSetData) {
        if (res.data.merchantSetData.kvkNumber) {
          setKvkNumber(res.data.merchantSetData.kvkNumber)
        }
        if (res.data.merchantSetData.companyName) {
          setCompanyName(res.data.merchantSetData.companyName)
        }
        if (res.data.merchantSetData.companyName) {
          setCompanyLegalForm(res.data.merchantSetData.companyLegalForm)
        }
        if (res.data.merchantSetData.businessSector) {
          setBusinessSector(
            res.data.merchantSetData.businessSector.map(
              (sector) => sector.value
            )
          )
        }
        if (res.data.merchantSetData.useCase) {
          setUseCase(res.data.merchantSetData.useCase)
        }
        if (res.data.merchantSetData.salesChannel) {
          setSalesChannel(res.data.merchantSetData.salesChannel)
        }
        if (res.data.merchantSetData.vatNumber) {
          setVatNumber(res.data.merchantSetData.vatNumber)
        }
        // if (res.data.merchantSetData.activity) {
        //   setActivity(res.data.merchantSetData.activity)
        // }
        if (res.data.merchantSetData.website) {
          setWebsite(res.data.merchantSetData.website)
        }
      }

      setIsDisabled(false)
    } catch (e) {
      console.log(e)
      setIsDisabled(false)
    }
  }

  const getMerchantAddress = async () => {
    try {
      setIsDisabled(true)

      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/addresses`,
        {
          withCredentials: true,
        }
      )

      if (res.data.merchantSetAddresses) {
        const { registered, trading } = res.data.merchantSetAddresses

        if (registered.line1) {
          setAddress1(registered.line1)
        }
        if (registered.line2) {
          setAddress2(registered.line2)
        }
        if (registered.city) {
          setCity(registered.city)
        }
        if (registered.postcode) {
          setPostalCode(registered.postcode)
        }
        if (registered.country) {
          setCountry(findCountryByValue(registered.country))
        }

        if (trading.line1) {
          setTradingAddress1(trading.line1)
        }
        if (trading.line2) {
          setTradingAddress2(trading.line2)
        }
        if (trading.city) {
          setTradingCity(trading.city)
        }
        if (trading.postcode) {
          setTradingPostalCode(trading.postcode)
        }
        if (trading.country) {
          setTradingCountry(findCountryByValue(trading.country))
        }
      }

      setIsDisabled(false)
    } catch (e) {
      setIsDisabled(false)
      console.log(e)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsDisabled(true)

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/data`,
        {
          kvkNumber,
          companyName,
          vatNumber,
          website,
          companyLegalForm,
          businessSector: businessSector.map((sector) => {
            return gicsList(gicsTrans).find((item) => item.value === sector)
          }),
          activity,
          useCase,
          salesChannel,
          country: 'NL',
        },
        {
          withCredentials: true,
        }
      )

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/addresses`,
        {
          registered: {
            line1: address1,
            line2: address2,
            city: city,
            postcode: postalCode,
            country: country.value,
          },
          trading: {
            line1: tradingAddress1,
            line2: tradingAddress2,
            city: tradingCity,
            postcode: tradingPostalCode,
            country: tradingCountry.value,
          },
        },
        {
          withCredentials: true,
        }
      )

      setEdit(false)
    } catch (e) {
      console.log(e)
      setEdit(false)
      setIsDisabled(false)
    }
  }

  useEffect(() => {
    getMerchantData()
    getMerchantAddress()
  }, [])

  useEffect(() => {
    if (
      kvkNumber !== '' &&
      companyName !== '' &&
      vatNumber !== '' &&
      companyLegalForm.value !== '' &&
      businessSector?.at(-1)?.length !== 0 &&
      // activity.value !== '' &&
      useCase.value !== '' &&
      salesChannel.value !== ''
    ) {
      setIsProceedDisabled(false)
    } else {
      setIsProceedDisabled(true)
    }
  }, [
    kvkNumber,
    companyName,
    vatNumber,
    companyLegalForm,
    businessSector,
    // activity,
    useCase,
    salesChannel,
  ])

  return (
    <Container>
      <h3>{companyName}</h3>
      {/* {!edit && (
        <button onClick={() => setEdit(!edit)} className="edit">
          <Icon size="24">
            <img src={IconPencil} alt="Edit" />
          </Icon>
        </button>
      )} */}
      {edit ? (
        <form onSubmit={(e) => e.preventDefault()}>
          <FormSection>
            <InputWrap>
              <label htmlFor="kvkNumber">{trans.kvknumber}</label>
              <AnimatedBorder isLoading={isDisabled}>
                <TextInput
                  type="text"
                  value={kvkNumber}
                  onChange={(e) => setKvkNumber(e.target.value)}
                  name="kvkNumber"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
          </FormSection>
          <InputWrap>
            <label htmlFor="companyName">{trans.companyName}</label>
            <AnimatedBorder isLoading={isDisabled} isFilled={true}>
              <TextInput
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                name="companyName"
                disabled={isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="companyLegalForm">{trans.companyLegalForm}</label>
            <AnimatedBorder isLoading={isDisabled}>
              <SimpleSelect
                name="companyLegalForm"
                value={companyLegalForm}
                onChange={(val) => setCompanyLegalForm(val as SelectItem)}
                options={CompanyLegalFormOptions}
                isMulti={false}
                isDisabled={isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="vatNumber">{trans.vatNumber}</label>
            <AnimatedBorder isLoading={isDisabled} isFilled={true}>
              <TextInput
                type="text"
                value={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
                name="vatNumber"
                disabled={isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="businessSector">{trans.businessSector}</label>
            <AnimatedBorder isLoading={isDisabled}>
              <GicsSelect
                value={businessSector}
                onChange={(val) => setBusinessSector(val as GicsSelectValue)}
                disabled={isDisabled}
                trans={gicsTrans}
              />
            </AnimatedBorder>
          </InputWrap>
          {/* <InputWrap>
            <label htmlFor="activity">{trans.activity}</label>
            <AnimatedBorder isLoading={isDisabled} isFilled={true}>
              <SimpleSelect
                name="activity"
                value={activity}
                onChange={(val) => setActivity(val as SelectItem)}
                options={mockOptions}
                isMulti={false}
                isDisabled={isDisabled}
              />
            </AnimatedBorder>
          </InputWrap> */}
          <InputWrap>
            <label htmlFor="useCase">{trans.useCase}</label>
            <AnimatedBorder isLoading={isDisabled}>
              <SimpleSelect
                name="useCase"
                value={useCase}
                onChange={(val) => setUseCase(val as SelectItem)}
                options={UseCaseOptions}
                isMulti={false}
                isDisabled={isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="salesChannel">{trans.salesChannel}</label>
            <AnimatedBorder isLoading={isDisabled}>
              <SimpleSelect
                name="salesChannel"
                value={salesChannel}
                onChange={(val) => setSalesChannel(val as SelectItem)}
                options={SalesChannelOptions}
                isMulti={false}
                isDisabled={isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="website">{trans.website}</label>
            <AnimatedBorder isLoading={isDisabled} isFilled={true}>
              <TextInput
                type="text"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                name="website"
                disabled={isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>

          <h3>{transAddress.registrationAddress}</h3>
          <FormSection>
            <InputWrap>
              <label htmlFor="address1">
                {transAddress.address} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <TextInput
                  type="text"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  name="address1"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="address2">{transAddress.address} 2</label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <TextInput
                  type="text"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  name="address2"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="city">
                {transAddress.city} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <TextInput
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  name="city"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="postalCode">
                {transAddress.postalCode} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <TextInput
                  type="text"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  name="postalCode"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="country">
                {transAddress.companyCountry} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <CountrySelect
                  value={country}
                  onChange={(val) => setCountry(val as CountrySelectItem)}
                  name="country"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
          </FormSection>
          <FormSection>
            <h3>{transAddress.tradingAddress}</h3>
            <InputWrap>
              <label
                htmlFor="showTrading"
                onClick={() => setShowTrading(false)}
              >
                <InputRadio
                  name="showTrading"
                  type="radio"
                  checked={!showTrading}
                  onChange={() => setShowTrading(false)}
                />
                {transAddress.sameAsRegistrationAddress}
              </label>
            </InputWrap>
            <InputWrap>
              <label htmlFor="showTrading" onClick={() => setShowTrading(true)}>
                <InputRadio
                  name="showTrading"
                  type="radio"
                  checked={showTrading}
                  onChange={() => setShowTrading(true)}
                />
                {transAddress.differentThanRegistrationAddress}
              </label>
            </InputWrap>
          </FormSection>
          <FormSectionOptional visible={showTrading}>
            <InputWrap>
              <label htmlFor="tradingAddress1">
                {transAddress.address} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <TextInput
                  type="text"
                  value={tradingAddress1}
                  onChange={(e) => setTradingAddress1(e.target.value)}
                  name="tradingAddress1"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="tradingAddress2">{transAddress.address} 2</label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <TextInput
                  type="text"
                  value={tradingAddress2}
                  onChange={(e) => setTradingAddress2(e.target.value)}
                  name="tradingAddress2"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="tradingCity">
                {transAddress.city} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <TextInput
                  type="text"
                  value={tradingCity}
                  onChange={(e) => setTradingCity(e.target.value)}
                  name="tradingCity"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="tradingPostalCode">
                {transAddress.postalCode} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <TextInput
                  type="text"
                  value={tradingPostalCode}
                  onChange={(e) => setTradingPostalCode(e.target.value)}
                  name="tradingPostalCode"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="tradingCountry">
                {transAddress.country} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={true}>
                <CountrySelect
                  value={tradingCountry}
                  onChange={(val) =>
                    setTradingCountry(val as CountrySelectItem)
                  }
                  name="tradingCountry"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
          </FormSectionOptional>
          <ToRight>
            <ProceedButton
              disabled={isProceedDisabled}
              onClick={() => handleSubmit()}
              {...theme.proceedButton}
            >
              {trans.save}
            </ProceedButton>
          </ToRight>
        </form>
      ) : (
        <>
          <div className="value-text">
            <span>{trans.kvknumber}</span>
            {kvkNumber}
          </div>

          <div className="value-text">
            <span>{trans.companyName}</span>
            {companyName}
          </div>

          <div className="value-text">
            <span>{trans.companyLegalForm}</span>
            {companyLegalForm && companyLegalForm.label}
          </div>

          <div className="value-text">
            <span>{trans.vatNumber}</span>
            {vatNumber}
          </div>

          <div className="value-text">
            <span>{trans.businessSector}</span>
            {businessSector
              .map((sector) => {
                const gics = gicsList(gicsTrans).find(
                  (item) => item.value === sector
                )
                return gics?.label
              })
              .join(' / ')}
          </div>

          {/* <div className="value-text">
            <span>{trans.activity}</span>
            {activity && activity.label}
          </div> */}

          <div className="value-text">
            <span>{trans.useCase}</span>
            {useCase && useCase.label}
          </div>

          <div className="value-text">
            <span>{trans.salesChannel}</span>
            {salesChannel && salesChannel.label}
          </div>

          <div className="value-text">
            <span>{trans.website}</span>
            {website}
          </div>

          <div className="value-text">
            <span>{transAddress.registrationAddress}</span>
            {address1}
            {address2 && ` ${address2}`}, {postalCode} {city},{' '}
            {country.value !== '' && country.label}
          </div>

          <div className="value-text">
            <span>{transAddress.tradingAddress}</span>
            {tradingAddress1}
            {tradingAddress2 && ` ${tradingAddress2}`}, {tradingPostalCode}{' '}
            {tradingCity}, {tradingCountry.value !== '' && tradingCountry.label}
          </div>
        </>
      )}
    </Container>
  )
})
