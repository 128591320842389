import React, { useContext, useEffect, useState } from 'react'
import {
  Container,
  InputWrap,
  Asterisk,
  ErrorMessage,
} from './PersonCard.styles'
import { AnimatedBorder } from '../../components/AnimatedInput/AnimatedBorder'
import { observer } from 'mobx-react'
import { StoreContext } from '../../components/App'

import { StatusInfo } from '../../components/StatusInfo/StatusInfo'
import { Icon } from '../Icons/Icon.styles'
import IconEdit from '../../assets/pencil.svg'
import IconDelete from '../../assets/delete.svg'
import IconCalendar from '../../assets/calendar.svg'
import IconPin from '../../assets/pin.svg'
import IconAtSign from '../../assets/at_sign.svg'
import { CountrySelect } from '../../components/CountrySelect/CountrySelect'
import {
  formatDate,
  getActorsDisplayName,
  getFormattedDate,
} from '../../methods/getActorFunctions'
import { TextInput } from '../../components/TextInput/TextInput'
import { Button } from '../../pages/EnrollCompany/subpages/enroll.styles'
import { Actor } from '../../pages/EnrollCompany/subpages/Actor.interface'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { CountryPrefixSelect } from '../../components/CountryPrefixSelect/CountryPrefixSelect'

interface Person {
  actorId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any // To allow dynamic properties
}

interface PersonProps {
  isLoading?: boolean
  initialEdit?: boolean
  person: Actor
  onChange: (actor: Actor) => void
  onDelete?: (id: string) => void
  onEdit?: () => void
  resendEmail?: (id: string) => void
  isResending?: boolean
}

export const PersonCard: React.FC<PersonProps> = observer(
  ({
    initialEdit,
    person,
    onChange,
    onDelete,
    onEdit,
    resendEmail,
    isLoading,
    isResending,
  }) => {
    const store = useContext(StoreContext)
    const { theme } = store.AppState

    const {
      formLabels: transForm,
      formErrors: transFormErrors,
      enrollCompanyPage: { summary: trans },
    } = store.TranslationsState.translations

    const [edit, setEdit] = useState(initialEdit)
    const [status, setStatus] = useState({ value: '', label: '' })
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] =
      useState<string>(null)

    useEffect(() => {
      if (person.isVerified) {
        setStatus({ value: 'positive', label: trans.approved })
      } else {
        setStatus({ value: 'notice', label: trans.inProgress })
      }
    }, [person])

    useEffect(() => {
      if (!initialEdit) setEdit(initialEdit)
    }, [initialEdit])

    return (
      <Container {...(!edit ? { color: '##2B253A' } : {})}>
        {!edit && (
          <>
            {/* <button
              onClick={() => {
                setEdit(!edit)
                onEdit()
              }}
              className="edit"
            >
              <Icon size="24">
                <img src={IconEdit} alt="Edit" />
              </Icon>
            </button> */}
            {status.value && (
              <StatusInfo status={status.value}>{status.label}</StatusInfo>
            )}
          </>
        )}

        <h3>{getActorsDisplayName(person)}</h3>

        {edit ? (
          <>
            <InputWrap>
              <label htmlFor="firstName">
                {transForm.firstName} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isLoading}>
                <TextInput
                  name="firstName"
                  type="text"
                  value={
                    person.info && person.info.firstName
                      ? person.info.firstName
                      : ''
                  }
                  onChange={(e) =>
                    onChange({
                      ...person,
                      info: {
                        ...person.info,
                        firstName: e.target.value,
                      },
                    })
                  }
                  disabled={isLoading}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="infix">{transForm.infix}</label>
              <AnimatedBorder isLoading={isLoading}>
                <TextInput
                  name="infix"
                  type="text"
                  value={
                    person.info && person.info.infix ? person.info.infix : ''
                  }
                  onChange={(e) =>
                    onChange({
                      ...person,
                      info: {
                        ...person.info,
                        infix: e.target.value,
                      },
                    })
                  }
                  disabled={isLoading}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="lastName">
                {transForm.lastName} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isLoading}>
                <TextInput
                  name="lastName"
                  type="text"
                  value={
                    person.info && person.info.lastName
                      ? person.info.lastName
                      : ''
                  }
                  onChange={(e) =>
                    onChange({
                      ...person,
                      info: {
                        ...person.info,
                        lastName: e.target.value,
                      },
                    })
                  }
                  disabled={isLoading}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="phoneNumber">
                {transForm.phoneNumber} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isLoading}>
                {/* <TextInput
                  name="phoneNumber"
                  type="text"
                  value={
                    person.info && person.info.phoneNumber
                      ? person.info.phoneNumber
                      : ''
                  }
                  onChange={(e) =>
                    onChange({
                      ...person,
                      info: {
                        ...person.info,
                        phoneNumber: e.target.value,
                      },
                    })
                  }
                  disabled={isLoading}
                /> */}
                <CountryPrefixSelect
                  phoneErrorLabel={transFormErrors.wrongPhoneNumber}
                  name="phoneNumber"
                  selectStyles={theme.selectInput}
                  inputStyles={theme.textInput}
                  value={
                    person.info && person.info.phoneNumber
                      ? person.info.phoneNumber
                      : ''
                  }
                  defaultCountryCode={'NL'}
                  phoneRegex={'^\\+?[1-9]\\d{1,14}$'}
                  required={true}
                  onChange={(e) =>
                    onChange({
                      ...person,
                      info: {
                        ...person.info,
                        phoneNumber: e,
                      },
                    })
                  }
                  onError={(e) => setPhoneNumberErrorMessage(e)}
                />
              </AnimatedBorder>
              {phoneNumberErrorMessage && (
                <ErrorMessage>{phoneNumberErrorMessage}</ErrorMessage>
              )}
            </InputWrap>
            <InputWrap>
              <label htmlFor="email">
                {transForm.email} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isLoading}>
                <TextInput
                  name="email"
                  type="text"
                  value={
                    person.info && person.info.email ? person.info.email : ''
                  }
                  onChange={(e) =>
                    onChange({
                      ...person,
                      info: {
                        ...person.info,
                        email: e.target.value,
                      },
                    })
                  }
                  disabled={isLoading}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="dateofbirth">
                {transForm.dateOfBirth} <Asterisk>*</Asterisk>
              </label>
              <AnimatedBorder isLoading={isLoading}>
                <TextInput
                  name="dateofbirth"
                  type="date"
                  defaultValue={getFormattedDate(person)}
                  onChange={(e) =>
                    onChange({
                      ...person,
                      info: {
                        ...person.info,
                        dateOfBirth: new Date(e.target.value).getTime(),
                      },
                    })
                  }
                  disabled={isLoading}
                />
              </AnimatedBorder>
            </InputWrap>
            {/* <InputWrap>
          <label htmlFor="address1">{transForm.line1}</label>
          <AnimatedBorder isLoading={isLoading}>
            <TextInput
              name="address1"
              type="text"
              value={
                person.address && person.address.line1
                  ? person.address.line1
                  : person.providerInfo.address
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    line1: e.target.value,
                  },
                })
              }
              disabled={isLoading}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="address2">{transForm.line2}</label>
          <AnimatedBorder isLoading={isLoading}>
            <TextInput
              name="address2"
              type="text"
              value={
                person.address && person.address.line2
                  ? person.address.line2
                  : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    line2: e.target.value,
                  },
                })
              }
              disabled={isLoading}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="city">{transForm.city}</label>
          <AnimatedBorder isLoading={isLoading}>
            <TextInput
              name="city"
              type="text"
              value={
                person.address && person.address.city ? person.address.city : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    city: e.target.value,
                  },
                })
              }
              disabled={isLoading}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="postcode">{transForm.postcode}</label>
          <AnimatedBorder isLoading={isLoading}>
            <TextInput
              name="postcode"
              type="text"
              value={
                person.address && person.address.postcode
                  ? person.address.postcode
                  : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    postcode: e.target.value,
                  },
                })
              }
              disabled={isLoading}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="country">{transForm.country}</label>
          <AnimatedBorder isLoading={isLoading}>
            <CountrySelect
              name="country"
              value={
                person.address && person.address.country
                  ? person.address.country
                  : ''
              }
              onChange={(val) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    country: val,
                  },
                })
              }
              disabled={isLoading}
            />
          </AnimatedBorder>
        </InputWrap> */}
          </>
        ) : (
          <>
            {(person.info?.dateOfBirth || person.providerInfo?.dateOfBirth) && (
              <div className="icon-text">
                <Icon size="24">
                  <img src={IconCalendar} alt="Calendar" />
                </Icon>
                {person.info && getFormattedDate(person)}
              </div>
            )}

            {person.isVerified && person.address?.line1 && (
              <div className="icon-text">
                <Icon size="24">
                  <img src={IconPin} alt="Pin" />
                </Icon>
                {`${person.address.line1}${
                  person.address.line2 && ` ${person.address.line2}`
                }, ${person.address.postcode} ${person.address.city}, ${
                  person.address.country
                }`}
              </div>
            )}

            <div className="icon-text">
              <Icon size="24">
                <img src={IconAtSign} alt="E-mail" />
              </Icon>
              {person.info && person.info.email}
            </div>

            {!person.isApplicant && resendEmail && (
              <div className="verification-info">
                {person.isVerified && (
                  <div>
                    <span className="label">
                      {trans.resultOfIdentityVerification}:
                    </span>
                    <span className="value">{status.label}</span>
                  </div>
                )}

                {person.isVerified ? (
                  <div>
                    <span className="label">
                      {trans.dateOfIdentityVerification}:
                    </span>
                    <span className="value">
                      {person.successfulVerification
                        ? formatDate(person.successfulVerification)
                        : '-'}
                    </span>
                  </div>
                ) : (
                  <>
                    <div>
                      <span className="label">{trans.lastNotification}:</span>
                      <span className="value">
                        {person.lastEmailSent
                          ? formatDate(person.lastEmailSent)
                          : '-'}
                      </span>
                    </div>
                    <Button
                      onClick={() =>
                        !isResending && resendEmail(person.actorId)
                      }
                      {...theme.button}
                      width="250px"
                      height="44px"
                    >
                      {isResending ? (
                        <LoadingSpinner
                          width="16px"
                          {...theme.loadingSpinner}
                          padding="0 0"
                          thickness={2}
                        />
                      ) : (
                        trans.resendNotification
                      )}
                    </Button>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </Container>
    )
  }
)
