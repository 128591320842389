import { observer } from 'mobx-react'
import axios from 'axios'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  SimpleSelect,
  SelectItem,
} from '../../../components/SimpleSelect/SimpleSelect'
import { InputWrap } from '../../../components/AddMerchantForm/AddMerchantForm.styles'
import { StoreContext } from '../../../components/App'
import { TextInput } from '../../../components/TextInput/TextInput'
import {
  Boxed,
  Container,
  Content,
  FormSection,
  ProceedButton,
} from './enroll.styles'
import { AnimatedBorder } from '../../../components/AnimatedInput/AnimatedBorder'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import {
  GicsSelect,
  GicsSelectValue,
} from '../../../components/GicsSelect/GicsSelect'
import { gicsList } from '../../../constants/gicsFlat'
import { Button, ToRight } from '../EnrollCompany.styles'
import {
  Asterisk,
  ErrorMessage,
} from '../../../components/PersonCard/PersonCard.styles'
import { ErrorInfo } from '../../../components/ErrorInfo/ErrorInfo'

const mockOptions: SelectItem[] = [
  { value: '1', label: '???' },
  { value: '2', label: '???' },
  { value: '3', label: '???' },
  { value: '4', label: '???' },
]

export const CompanyData = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme, themeName } = store.AppState
  const {
    formErrors: transFormErrors,
    enrollCompanyPage: { companyData: trans, gicsDataSet: gicsTrans },
  } = store.TranslationsState.translations

  const [isPolling, setIsPolling] = useState(false)

  const pollVerificationData = async () => {
    try {
      const res = await axios.get<{
        redirectUrl?: string
      }>(`${process.env.WEB_API_URL}/merchant-enrolment/should-continue`, {
        withCredentials: true,
      })

      if (res.data.redirectUrl) {
        setIsPolling(false)
        window.location.href = res.data.redirectUrl
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [isDisabled, setIsDisabled] = useState(false)
  const [isProceedDisabled, setIsProceedDisabled] = useState(true)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [dataAlreadyProvided, setDataAlreadyProvided] = useState(false)
  const [kvkNumber, setKvkNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [vatNumber, setVatNumber] = useState('')
  const [website, setWebsite] = useState('')
  const [companyLegalForm, setCompanyLegalForm] = useState({
    value: '',
    label: '',
  })
  const [businessSector, setBusinessSector] = useState<GicsSelectValue>([])
  const [activity, setActivity] = useState({ value: '', label: '' })
  const [useCase, setUseCase] = useState({ value: '', label: '' })
  const [salesChannel, setSalesChannel] = useState({ value: '', label: '' })

  const [kvkNumberFetched, setKvkNumberFetched] = useState(false)
  const [companyNameFetched, setCompanyNameFetched] = useState(false)
  const [companyLegalFormFetched, setCompanyLegalFormFetched] = useState(false)
  const [businessSectorFetched, setBusinessSectorFetched] = useState(false)
  const [useCaseFetched, setUseCaseFetched] = useState(false)
  const [salesChannelFetched, setSalesChannelFetched] = useState(false)

  const [vatNumberFetched, setVatNumberFetched] = useState(false)
  const [websiteFetched, setWebsiteFetched] = useState(false)

  const [vatNumberErrorMessage, setVatNumberErrorMessage] =
    useState<string>(null)
  const [kvkNumberErrorMessage, setKvkNumberErrorMessage] =
    useState<string>(null)
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState<string>(null)

  const CompanyLegalFormOptions: SelectItem[] = useMemo(
    () => [
      { value: 'soleProprietorship', label: trans.soleProprietorship },
      { value: 'private', label: trans.private },
      { value: 'partnership', label: trans.partnership },
      { value: 'selfEmployed', label: trans.selfEmployed },
      { value: 'foundation', label: trans.foundation },
      { value: 'association', label: trans.association },
      { value: 'branch', label: trans.branch },
      {
        value: 'associationInclCooperative',
        label: trans.associationInclCooperative,
      },
    ],
    [trans]
  )

  const SalesChannelOptions: SelectItem[] = useMemo(
    () => [
      { value: 'ownstores', label: trans.ownstores },
      { value: 'reseller', label: trans.reseller },
      { value: 'thirdparty', label: trans.thirdparty },
    ],
    [trans]
  )

  const UseCaseOptions: SelectItem[] = useMemo(
    () => [
      { value: 'instore', label: trans.instore },
      { value: 'online', label: trans.online },
      { value: 'both', label: trans.both },
      { value: 'other', label: trans.other },
    ],
    [trans]
  )

  const getSphonicData = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/company-lookup`,
        {
          companyNumber: kvkNumber,
          country: 'NL',
        },
        {
          withCredentials: true,
        }
      )

      if (res.data) {
        if (res.data.companyName) {
          setCompanyName(res.data.companyName)
          setCompanyNameFetched(true)
        }
        if (res.data.vatNumber && res.data.vatNumber !== 'None') {
          setVatNumber(res.data.vatNumber)
          setVatNumberFetched(true)
        }
        if (res.data.website && res.data.website !== 'None') {
          setWebsite(res.data.website)
          setWebsiteFetched(true)
        }
      }

      setTimeout(() => {
        setCompanyNameFetched(false)
        setVatNumberFetched(false)
        setWebsiteFetched(false)
      }, 500)

      setIsDisabled(false)
    } catch (e) {
      console.log(e)
      setIsDisabled(false)
    }
  }

  const getCompanyData = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/data`,
        {
          withCredentials: true,
        }
      )

      if (res.data && res.data.merchantSetData) {
        setShouldFetch(false)

        if (res.data.merchantSetData.kvkNumber) {
          setKvkNumber(res.data.merchantSetData.kvkNumber)
          setKvkNumberFetched(true)
        }
        if (res.data.merchantSetData.companyName) {
          setCompanyName(res.data.merchantSetData.companyName)
          setCompanyNameFetched(true)
        }
        if (res.data.merchantSetData.companyName) {
          setCompanyLegalForm(res.data.merchantSetData.companyLegalForm)
          setCompanyLegalFormFetched(true)
        }
        if (res.data.merchantSetData.businessSector) {
          setBusinessSector(
            res.data.merchantSetData.businessSector.map(
              (sector) => sector.value
            )
          )
          setBusinessSectorFetched(true)
        }
        if (res.data.merchantSetData.useCase) {
          setUseCase(res.data.merchantSetData.useCase)
          setUseCaseFetched(true)
        }
        if (res.data.merchantSetData.salesChannel) {
          setSalesChannel(res.data.merchantSetData.salesChannel)
          setSalesChannelFetched(true)
        }
        if (res.data.merchantSetData.vatNumber) {
          setVatNumber(res.data.merchantSetData.vatNumber)
          setVatNumberFetched(true)
        }
        if (res.data.merchantSetData.website) {
          setWebsite(res.data.merchantSetData.website)
          setWebsiteFetched(true)
        }

        setDataAlreadyProvided(true)
      }

      setTimeout(() => {
        setKvkNumberFetched(false)
        setCompanyNameFetched(false)
        setCompanyLegalFormFetched(false)
        setBusinessSectorFetched(false)
        setUseCaseFetched(false)
        setSalesChannelFetched(false)
        setVatNumberFetched(false)
        setWebsiteFetched(false)
      }, 500)

      setIsDisabled(false)
    } catch (e) {
      console.log(e)
      setIsDisabled(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsDisabled(true)

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/data`,
        {
          kvkNumber,
          companyName,
          vatNumber,
          website,
          companyLegalForm,
          businessSector: businessSector.map((sector) => {
            return gicsList(gicsTrans).find((item) => item.value === sector)
          }),
          activity,
          useCase,
          salesChannel,
          country: 'NL',
        },
        {
          withCredentials: true,
        }
      )

      setIsPolling(true)
    } catch (e) {
      console.log(e)
      setIsDisabled(false)
    }
  }

  const handleOnBlur = () => {
    if (kvkNumber.length === 8) {
      getSphonicData()
    }
  }

  useEffect(() => {
    getCompanyData()
  }, [])

  useEffect(() => {
    let websiteValid: boolean = true
    if (website !== '' && !isValidURL(website)) {
      websiteValid = false
    }

    if (
      kvkNumber !== '' &&
      /\d{8}$/.test(kvkNumber) &&
      companyName !== '' &&
      vatNumber !== '' &&
      /^NL\d{9}B\d{2}$/.test(vatNumber) &&
      companyLegalForm.value !== '' &&
      businessSector?.at(-1)?.length !== 0 &&
      useCase.value !== '' &&
      salesChannel.value !== '' &&
      websiteValid
    ) {
      setIsProceedDisabled(false)
    } else {
      setIsProceedDisabled(true)
    }
  }, [
    kvkNumber,
    companyName,
    vatNumber,
    companyLegalForm,
    businessSector,
    useCase,
    salesChannel,
    website,
  ])

  useEffect(() => {
    if (kvkNumber !== '' && !/\d{8}$/.test(kvkNumber)) {
      setKvkNumberErrorMessage(transFormErrors.wrongKvkNumber)
    } else {
      setKvkNumberErrorMessage(null)
    }
  }, [kvkNumber, trans])

  useEffect(() => {
    if (vatNumber !== '' && !/^NL\d{9}B\d{2}$/.test(vatNumber)) {
      setVatNumberErrorMessage(transFormErrors.wrongVatNumber)
    } else {
      setVatNumberErrorMessage(null)
    }
  }, [vatNumber, trans])

  const isValidURL = (string) => {
    const res = string.match(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    )
    return res !== null
  }

  useEffect(() => {
    if (website !== '' && !isValidURL(website)) {
      setWebsiteErrorMessage(transFormErrors.wrongUrl)
    } else {
      setWebsiteErrorMessage(null)
    }
  }, [website, trans])

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(pollVerificationData, 3000) // Poll every 3 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        margin="0 0 0 40px"
        marginMobile="0 0 0 80px"
      />
      <Content>
        <Boxed>
          <form onSubmit={(e) => e.preventDefault()}>
            <FormSection>
              <InputWrap>
                <label htmlFor="kvkNumber">
                  {trans.kvknumber} <Asterisk>*</Asterisk>
                </label>
                <AnimatedBorder
                  isLoading={isDisabled}
                  isFilled={kvkNumberFetched}
                >
                  <TextInput
                    type="text"
                    value={kvkNumber}
                    onChange={(e) => setKvkNumber(e.target.value)}
                    onBlur={handleOnBlur}
                    name="kvkNumber"
                    disabled={isDisabled}
                  />
                </AnimatedBorder>
                {kvkNumberErrorMessage && (
                  <ErrorMessage>{kvkNumberErrorMessage}</ErrorMessage>
                )}
              </InputWrap>

              <InputWrap>
                <label htmlFor="companyName">
                  {trans.companyName} <Asterisk>*</Asterisk>
                </label>
                <AnimatedBorder
                  isLoading={isDisabled}
                  isFilled={companyNameFetched}
                >
                  <TextInput
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    name="companyName"
                    disabled={isDisabled}
                  />
                </AnimatedBorder>
              </InputWrap>
              <InputWrap>
                <label htmlFor="companyLegalForm">
                  {trans.companyLegalForm} <Asterisk>*</Asterisk>
                </label>
                <AnimatedBorder
                  isLoading={isDisabled}
                  isFilled={companyLegalFormFetched}
                >
                  <SimpleSelect
                    name="companyLegalForm"
                    value={companyLegalForm}
                    onChange={(val) => setCompanyLegalForm(val as SelectItem)}
                    options={CompanyLegalFormOptions}
                    isMulti={false}
                    isDisabled={isDisabled}
                  />
                </AnimatedBorder>
              </InputWrap>
              <InputWrap>
                <label htmlFor="vatNumber">
                  {trans.vatNumber} <Asterisk>*</Asterisk>
                </label>
                <AnimatedBorder
                  isLoading={isDisabled}
                  isFilled={vatNumberFetched}
                >
                  <TextInput
                    type="text"
                    value={vatNumber}
                    onChange={(e) => setVatNumber(e.target.value)}
                    name="vatNumber"
                    disabled={isDisabled}
                  />
                </AnimatedBorder>
                {vatNumberErrorMessage && (
                  <ErrorMessage>{vatNumberErrorMessage}</ErrorMessage>
                )}
              </InputWrap>
              <InputWrap>
                <label htmlFor="businessSector">
                  {trans.businessSector} <Asterisk>*</Asterisk>
                </label>
                <AnimatedBorder
                  isLoading={isDisabled}
                  isFilled={businessSectorFetched}
                >
                  <GicsSelect
                    value={businessSector}
                    onChange={(val) =>
                      setBusinessSector(val as GicsSelectValue)
                    }
                    disabled={isDisabled}
                    trans={gicsTrans}
                  />
                </AnimatedBorder>
              </InputWrap>
              <InputWrap>
                <label htmlFor="useCase">
                  {trans.useCase} <Asterisk>*</Asterisk>
                </label>
                <AnimatedBorder
                  isLoading={isDisabled}
                  isFilled={useCaseFetched}
                >
                  <SimpleSelect
                    name="useCase"
                    value={useCase}
                    onChange={(val) => setUseCase(val as SelectItem)}
                    options={UseCaseOptions}
                    isMulti={false}
                    isDisabled={isDisabled}
                  />
                </AnimatedBorder>
              </InputWrap>
              <InputWrap>
                <label htmlFor="salesChannel">
                  {trans.salesChannel} <Asterisk>*</Asterisk>
                </label>
                <AnimatedBorder
                  isLoading={isDisabled}
                  isFilled={salesChannelFetched}
                >
                  <SimpleSelect
                    name="salesChannel"
                    value={salesChannel}
                    onChange={(val) => setSalesChannel(val as SelectItem)}
                    options={SalesChannelOptions}
                    isMulti={false}
                    isDisabled={isDisabled}
                  />
                </AnimatedBorder>
              </InputWrap>
              <InputWrap>
                <label htmlFor="website">{trans.website}</label>
                <AnimatedBorder
                  isLoading={isDisabled}
                  isFilled={websiteFetched}
                >
                  <TextInput
                    type="text"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    name="website"
                    disabled={isDisabled}
                  />
                </AnimatedBorder>
                {websiteErrorMessage && (
                  <ErrorMessage>{websiteErrorMessage}</ErrorMessage>
                )}
              </InputWrap>
            </FormSection>
          </form>
          <ToRight>
            {dataAlreadyProvided ? (
              <Link
                to={`/enroll-company/business-address?themeId=${themeName}`}
              >
                <Button type="button">{trans.continue}</Button>
              </Link>
            ) : (
              <ProceedButton
                disabled={isProceedDisabled}
                {...theme.proceedButton}
                onClick={() => handleSubmit()}
              >
                {trans.continue}
              </ProceedButton>
            )}
          </ToRight>
        </Boxed>
      </Content>
    </Container>
  )
})
